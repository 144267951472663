import React from 'react';
import Button from '../components/common/Button';

const PostListPage = () => {
  return(
	  <html>
    <meta http-equiv="refresh" content="0; url=https://canverse.yeongnam.com/"></meta>
</html>
  );
};

export default PostListPage;
